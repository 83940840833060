<template>
  <div class="content_body SalarySettlement">
    <!-- 头部 -->
    <div class="nav_header nav_header_1">
      <el-row>
        <el-col :span="21">
          <el-form :inline="true" size="small" @submit.native.prevent @keyup.enter.native="handleSearch">
            <el-form-item label="结算周期">
              <el-select v-model="searchData.SalarySettlementIntervalID" placeholder="选择职务" clearable filterable :default-first-option="true" size="small" popper-class="monthSel" @change="handleSearch">
                <el-option v-for="item in monthList" :key="item.ID" :label="item.SettlementMonth" :value="item.ID">
                  <div style="height:25px;line-height:30px">{{item.SettlementMonth}}</div>
                  <div style="height:18px;line-height:18px; font-size:12px" class="color_999">{{item.StartDate}} -
                    {{item.EndDate}}</div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工">
              <el-input v-model="searchData.employeeName" size="small" placeholder="输入员工姓名编号搜索" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="职务">
              <el-select v-model="searchData.JobID" placeholder="选择职务" clearable filterable :default-first-option="true" size="small" @change="handleSearch">
                <el-option v-for="item in jobTypeList" :key="item.ID" :label="item.JobName" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单位">
              <treeselect v-model="searchData.EntityID" :options="EntityAllEntity" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="选择所属单位" @input="handleSearch" />

            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="1" class="text_right">
          <el-button type="primary" size="small" v-prevent-click @click="downloadExcel" :loading="downloadLoading">导出
          </el-button>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" size="small" v-prevent-click @click="handleShow('Settlement','isSettlement')">业绩提成结算
          </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="martp_10">
      <el-table size="small" :data="tableData" v-loading="loading" tooltip-effect="light">
        <el-table-column prop="SettlementMonth" label="结算周期"></el-table-column>
        <el-table-column prop="EmployeeName" label="员工名称"></el-table-column>
        <el-table-column prop="EmployeeID" label="员工编号"></el-table-column>
        <el-table-column prop="JobName" label="职务"></el-table-column>
        <el-table-column prop="Entity" label="所属单位" show-overflow-tooltip min-width="140px">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.Entity" :key="index">{{ item.EntityName }}{{ item.IsPrimaryEntity ? "[主]" : ""
                    }}{{
                      scope.row.Entity.length == index + 1 ? "" : ","
                    }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="EmployeePerformanceCommission" label="个人业绩提成">
          <template slot-scope="scope">
            ￥{{scope.row.EmployeePerformanceCommission | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="EntityPerformanceCommission" label="门店业绩提成">
          <template slot-scope="scope">
            ￥{{scope.row.EntityPerformanceCommission | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="SaleCommission" label="销售提成">
          <template slot-scope="scope">
            ￥{{scope.row.SaleCommission | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="TreatCommission" label="消耗提成">
          <template slot-scope="scope">
            ￥{{scope.row.TreatCommission | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="TotalCommission" label="合计">
          <template slot-scope="scope">
            ￥{{scope.row.TotalCommission | NumFormat}}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="primary" size="small" v-prevent-click @click="handleShow('SettlementDetail','isSettlementDetail',scope.row)">结算明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    <!-- 结算周期设置 -->
    <el-dialog title="结算周期设置" :visible.sync="isSettlementSet" width="550px" @close="handleClose('SettlementSet','isSettlementSet','SettlementSetRef')">
      <el-form size="small" :model="SettlementSetData" ref="SettlementSetRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="结算月份" prop="SettlementMonth" :rules="[
         { required: true, message: '请输入结算月份', trigger: 'blur' },
        ]">
          <el-input v-model="SettlementSetData.SettlementMonth"></el-input>
        </el-form-item>
        <el-form-item label="结算周期" prop="QueryDate" :rules="[
         { required: true, message: '请输入结算周期', trigger: 'change' },
        ]">
          <el-date-picker v-model="SettlementSetData.QueryDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isSettlementSet=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('SettlementSet','isSettlementSet')" :loading="jsSetLoading">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 结算弹出层 -->
    <el-dialog title="业绩提成计算" :visible.sync="isSettlement" width="590px" @close="handleClose('Settlement','isSettlement','SettlementRef')">
      <el-form size="small" :model="SettlementData" ref="SettlementRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="结算月份" prop="SalarySettlementIntervalID" :rules="[{ required: true, message: '请选择结算月份', trigger: 'change' }]">
          <el-col :span="13">
            <el-select v-model="SettlementData.SalarySettlementIntervalID" placeholder="请选择" filterable clearable>
              <el-option v-for="item in monthList" :key="item.ID" :label="item.SettlementMonth" :value="item.ID">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11">
            <el-button type="primary" size="small" v-prevent-click @click="handleShow('SettlementSet','isSettlementSet')">
              结算周期设置</el-button>
          </el-col>

        </el-form-item>
        <el-form-item label="结算周期" prop="QueryDate">
          <el-date-picker v-model="SettlementData.QueryDate" :disabled="true" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isSettlement=false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave('Settlement','isSettlement')" :loading="jsSaveLoading">结 算
        </el-button>
      </span>
    </el-dialog>
    <!-- 结算明细弹出层 -->
    <el-dialog title="业绩提成结算明细" :visible.sync="isSettlementDetail" width="1100px" @close="handleClose('SettlementDetail','isSettlementDetail','SettlementDetailRef')">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="个人业绩提成" name="first">
          <el-table :data="SettlementDetail.first" height="450px" size="small">
            <el-table-column prop="CommissionName" label="提成名称">
            </el-table-column>
            <el-table-column prop="PerformanceName" label="业绩取值">
            </el-table-column>
            <el-table-column prop="Performance" label="业绩">
              <template slot-scope="scope">
                {{scope.row.Performance | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="Commission" label="提成">
              <template slot-scope="scope">
                {{scope.row.Commission | NumFormat}}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="门店业绩提成" name="second">
          <el-table :data="SettlementDetail.second" height="450px" size="small">
            <el-table-column prop="CommissionName" label="提成名称">
            </el-table-column>
            <el-table-column prop="PerformanceName" label="业绩取值">
            </el-table-column>
            <el-table-column prop="Performance" label="业绩">
              <template slot-scope="scope">
                {{scope.row.Performance | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="Commission" label="提成">
              <template slot-scope="scope">
                {{scope.row.Commission | NumFormat}}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="销售业绩提成" name="third">
          <el-table ref="third" :data="SettlementDetail.third.list" size="small" show-summary :summary-method="getSaleSummaries" height="450px">
            <el-table-column prop="SaleBillID" label="订单编号">
            </el-table-column>
            <el-table-column prop="BillDate" label="下单时间">
            </el-table-column>
            <el-table-column prop="EntityName" label="下单门店">
            </el-table-column>
            <el-table-column prop="GoodsTypeName" label="商品类型">
            </el-table-column>
            <el-table-column prop="GoodName" label="商品名称">
            </el-table-column>
            <el-table-column prop="PayPerformance" label="现金业绩">
              <template slot-scope="scope">
                {{scope.row.PayPerformance | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="PayCommission" label="现金提成">
              <template slot-scope="scope">
                {{scope.row.PayCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="SavingCardPerformance" label="卡扣业绩">
              <template slot-scope="scope">
                {{scope.row.SavingCardPerformance | NumFormat}}
              </template>
            </el-table-column>

            <el-table-column prop="SavingCardCommission" label="卡扣提成">
              <template slot-scope="scope">
                {{scope.row.SavingCardCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="SavingCardLargessPerformance" label="赠送卡扣业绩">
              <template slot-scope="scope">
                {{scope.row.SavingCardLargessPerformance | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="SavingCardLargessCardCommission" label="赠送卡扣提成">
              <template slot-scope="scope">
                {{scope.row.SavingCardLargessCardCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="SpecialBenefitCommission" label="特殊补贴">
              <template slot-scope="scope">
                {{scope.row.SpecialBenefitCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="TotalCommission" label="合计">
              <template slot-scope="scope">
                {{scope.row.TotalCommission | NumFormat}}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pad_15 text_right">
            <el-pagination background v-if="SaleDetailPaginations.total > 0" @current-change="handleSaleDetailCurrentChange" :current-page.sync="SaleDetailPaginations.page" :page-size="SaleDetailPaginations.page_size" :layout="SaleDetailPaginations.layout" :total="SaleDetailPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="消耗业绩提成" name="fourth">
          <el-table ref="fourth" size="small" :data="SettlementDetail.fourth.list" show-summary :summary-method="getTreatSummaries" height="450px">
            <el-table-column prop="TreatBillID" label="订单编号">
            </el-table-column>
            <el-table-column prop="BillDate" label="下单时间">
            </el-table-column>
            <el-table-column prop="EntityName" label="下单门店">
            </el-table-column>
            <el-table-column prop="TreatCardTypeName" label="商品类型">
            </el-table-column>
            <el-table-column prop="GoodName" label="商品名称">
            </el-table-column>
            <el-table-column prop="Performance" label="本金业绩">
              <template slot-scope="scope">
                {{scope.row.Performance | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="PerformanceCommission" label="本金提成">
              <template slot-scope="scope">
                {{scope.row.PerformanceCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="LargessPerformance" label="赠金业绩">
              <template slot-scope="scope">
                {{scope.row.LargessPerformance | NumFormat}}
              </template>
            </el-table-column>

            <el-table-column prop="LargessPerformanceCommission" label="赠金提成">
              <template slot-scope="scope">
                {{scope.row.LargessPerformanceCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="SpecialBenefitCommission" label="特殊补贴">
              <template slot-scope="scope">
                {{scope.row.SpecialBenefitCommission | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column prop="TotalCommission" label="合计">
              <template slot-scope="scope">
                {{scope.row.TotalCommission | NumFormat}}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pad_15 text_right">
            <el-pagination background v-if="TreatDetailPaginations.total > 0" @current-change="handleTreatDetailCurrentChange" :current-page.sync="TreatDetailPaginations.page" :page-size="TreatDetailPaginations.page_size" :layout="TreatDetailPaginations.layout" :total="TreatDetailPaginations.total"></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="isSettlementDetail=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/KHS/Salary/settlement";
import APIEntity from "@/api/KHS/Entity/entity";
import APIJob from "@/api/KHS/Entity/jobtype";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "SalarySettlement",

  components: {
    Treeselect,
  },

  directives: {},
  watch: {
    "SettlementData.SalarySettlementIntervalID"(ID) {
      if (ID) {
        const item = this.monthList.find((item) => item.ID == ID);
        if (item) {
          this.SettlementData.QueryDate = [item.StartDate, item.EndDate];
        }
      }
    },
    deep: true,
  },

  data() {
    return {
      loading: false,
      downloadLoading: false,
      jsSetLoading: false,
      jsSaveLoading: false,
      normalizer(node) {
        return {
          id: node.ID,
          label: node.EntityName,
          children: node.Child,
        };
      },
      EntityAllEntity: [], //门店
      jobTypeList: [], //职务
      monthList: [], // 月份列表
      // 搜索
      searchData: {
        employeeName: "",
        SalarySettlementIntervalID: "",
        JobID: "",
        EntityID: null,
      },
      // 列表
      tableData: [],
      // 分页
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      //结算设置弹层显示
      isSettlementSet: false,
      //结算设置弹层数据
      SettlementSetData: {
        SettlementMonth: "",
        QueryDate: [],
      },
      //结算弹层显示
      isSettlement: false,

      // 结算弹层数据
      SettlementData: {
        SalarySettlementIntervalID: "",
        QueryDate: [],
      },
      // 详情显示
      isSettlementDetail: false,
      // 详情弹层tab索引
      activeName: "first",
      // 详情员工信息
      employeeDetail: {},
      // 详情数据
      SettlementDetail: {
        first: [],
        second: [],
        third: {
          list: [],
          total: {},
        },
        fourth: {
          list: [],
          total: {},
        },
      },
      // 详情销售分页
      SaleDetailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      // 详情消耗分页
      TreatDetailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },

  mounted() {
    const that = this;
    that.handleSearch();
    that.getEntityAllEntity();
    that.jobTypeData();
    that.SettlementMonthList();
  },
  updated() {
    if (this.isSettlementDetail) {
      this.$nextTick(() => {
        if (this.activeName == "third" || this.activeName == "fourth")
          this.$refs[this.activeName].doLayout();
      });
    }
  },

  methods: {
    // 初始化-组织
    getEntityAllEntity() {
      var that = this;
      var params = {
        SearchKey: "",
      };
      APIEntity.getEntity(params).then((res) => {
        if (res.StateCode == 200) {
          this.EntityAllEntity = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //初始化-职务
    jobTypeData: function () {
      var that = this;
      var params = {
        JobTypeName: "",
      };
      APIJob.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 初始化-月份列表
    SettlementMonthList() {
      const that = this;
      API.SalarySheetAllSettlementInterval().then((res) => {
        if (res.StateCode == 200) {
          that.monthList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 搜索
    handleSearch() {
      this.paginations.page = 1;
      this.getSalarySheetAll();
    },
    // 列表
    getSalarySheetAll() {
      const that = this;
      const params = {
        PageNum: that.paginations.page,
        Name: that.searchData.employeeName,
        SalarySettlementIntervalID: that.searchData.SalarySettlementIntervalID,
        JobID: that.searchData.JobID,
        EntityID: that.searchData.EntityID,
      };
      that.loading = true;
      API.SalarySheetAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    // 分页
    handleCurrentChange() {
      this.getSalarySheetAll();
    },
    //数据导出
    downloadExcel() {
      var that = this;
      var params = {
        Name: that.searchData.employeeName,
        SalarySettlementIntervalID: that.searchData.SalarySettlementIntervalID,
        JobID: that.searchData.JobID,
        EntityID: that.searchData.EntityID,
      };

      that.downloadLoading = true;
      API.exportSalarySheetExce(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "业绩提成结算.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function () {
          that.downloadLoading = false;
        });
    },

    // 结算周期设置弹层-保存
    SettlementSetSavs() {
      const that = this;
      that.$refs.SettlementSetRef.validate((valid) => {
        if (valid) {
          const params = {
            SettlementMonth: that.SettlementSetData.SettlementMonth,
            StartDate: that.SettlementSetData.QueryDate[0],
            EndDate: that.SettlementSetData.QueryDate[1],
          };
          that.jsSetLoading = true;
          API.SalarySheetCreateSettlementInterval(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "添加成功",
                  duration: 2000,
                });
                that.isSettlementSet = false;
                that.SettlementMonthList();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(() => {
              that.jsSetLoading = false;
            });
        }
      });
    },
    // 结算弹层-保存
    SalarySheetSettlement() {
      const that = this;
      that.$refs.SettlementRef.validate((bool) => {
        if (bool) {
          const params = {
            SalarySettlementIntervalID:
              that.SettlementData.SalarySettlementIntervalID,
          };
          that.jsSaveLoading = true;
          API.SalarySheetSettlement(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "结算成功",
                  duration: 2000,
                });
                that.isSettlement = false;
                that.handleSearch();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(() => {
              that.jsSaveLoading = false;
            });
        }
      });
    },
    // 详情弹层-tab点击
    handleTabClick(tab) {
      const that = this;
      that.activeName = tab.name;
      if (
        (tab.name == "first" || tab.name == "second") &&
        !that.SettlementDetail[tab.name].length
      ) {
        switch (tab.name) {
          case "first":
            that.SalarySheetEmployeeCommission();
            break;
          case "second":
            that.SalarySheetEntityCommission();
            break;
        }
      } else if (
        (tab.name == "third" || tab.name == "fourth") &&
        !that.SettlementDetail[tab.name].list.length
      ) {
        switch (tab.name) {
          case "third":
            that.SalarySheetSaleCommission();
            break;
          case "fourth":
            that.SalarySheetTreatCommission();
            break;
        }
        // that.$nextTick(()=>{
        //   this.$refs[tab.name].doLayout()
        // });
      }
    },
    // 详情弹层-个人业绩提成
    async SalarySheetEmployeeCommission() {
      const that = this;
      const params = Object.assign({}, that.employeeDetail);
      var res = await API.SalarySheetEmployeeCommission(params);
      if (res.StateCode == 200) {
        that.SettlementDetail.first = res.Data;
      } else {
        that.$message.error({
          message: res.Message,
          duration: 2000,
        });
      }
    },
    // 详情弹层-门店业绩提成
    async SalarySheetEntityCommission() {
      const that = this;
      const params = Object.assign({}, that.employeeDetail);
      var res = await API.SalarySheetEntityCommission(params);
      if (res.StateCode == 200) {
        that.SettlementDetail.second = res.Data;
      } else {
        that.$message.error({
          message: res.Message,
          duration: 2000,
        });
      }
    },
    // 详情弹层-销售业绩提成
    async SalarySheetSaleCommission() {
      const that = this;
      const params = Object.assign({}, that.employeeDetail);
      params.PageNum = that.SaleDetailPaginations.page;
      var res = await API.SalarySheetSaleCommission(params);
      if (res.StateCode == 200) {
        that.SettlementDetail.third.list =
          res.Data.employeeSalePerformanceCommissionDetailStatementForms.List;
        that.SaleDetailPaginations.total =
          res.Data.employeeSalePerformanceCommissionDetailStatementForms.Total;
        that.SettlementDetail.third.total =
          res.Data.employeeSalePerformanceCommissionSumStatementForm;
      } else {
        that.$message.error({
          message: res.Message,
          duration: 2000,
        });
      }
    },
    // 详情弹层-消耗业绩提成
    async SalarySheetTreatCommission() {
      const that = this;
      const params = Object.assign({}, that.employeeDetail);
      params.PageNum = that.TreatDetailPaginations.page;
      var res = await API.SalarySheetTreatCommission(params);
      if (res.StateCode == 200) {
        that.SettlementDetail.fourth.list =
          res.Data.employeeTreatPerformanceCommissionDetailStatementForms.List;
        that.TreatDetailPaginations.total =
          res.Data.employeeTreatPerformanceCommissionDetailStatementForms.Total;
        that.SettlementDetail.fourth.total =
          res.Data.employeeTreatPerformanceCommissionSumStatementForm;
      } else {
        that.$message.error({
          message: res.Message,
          duration: 2000,
        });
      }
    },
    // 详情弹层-销售业绩提成-分页
    handleSaleDetailCurrentChange() {
      this.SalarySheetSaleCommission();
    },
    // 详情弹层-消耗业绩提成-分页
    handleTreatDetailCurrentChange() {
      this.SalarySheetTreatCommission();
    },
    //详情弹层-销售业绩提成-合计
    getSaleSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "PayPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.PayPerformance
                )}
              </span>
            );
            break;
          case "PayCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.PayCommission
                )}
              </span>
            );
            break;
          case "SavingCardPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.SavingCardPerformance
                )}
              </span>
            );
            break;
          case "SavingCardCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.SavingCardCommission
                )}
              </span>
            );
            break;
          case "SavingCardLargessPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.SavingCardLargessPerformance
                )}
              </span>
            );
            break;
          case "SavingCardLargessCardCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total
                    .SavingCardLargessCardCommission
                )}
              </span>
            );
            break;
          case "SpecialBenefitCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.SpecialBenefitCommission
                )}
              </span>
            );
            break;
          case "TotalCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.third.total.TotalCommission
                )}
              </span>
            );
            break;

          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    //详情弹层-消耗业绩提成-合计
    getTreatSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "Performance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total.Performance
                )}
              </span>
            );
            break;
          case "PerformanceCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total.PerformanceCommission
                )}
              </span>
            );
            break;
          case "LargessPerformance":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total.LargessPerformance
                )}
              </span>
            );
            break;
          case "LargessPerformanceCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total
                    .LargessPerformanceCommission
                )}
              </span>
            );
            break;
          case "SpecialBenefitCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total.SpecialBenefitCommission
                )}
              </span>
            );
            break;
          case "TotalCommission":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(
                  this.SettlementDetail.fourth.total.TotalCommission
                )}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },

    // 显示弹层
    async handleShow(type, bool, row) {
      const that = this;
      that[bool] = true;
      switch (type) {
        // 详情弹层
        case "SettlementDetail":
          that.employeeDetail = {
            EmployeeID: row.EmployeeID,
            SalarySettlementIntervalID: row.SalarySettlementIntervalID,
          };
          that.SalarySheetEmployeeCommission();
          break;
      }
    },
    // 保存弹层
    handleSave(type) {
      const that = this;
      switch (type) {
        // 结算周期设置弹层
        case "SettlementSet":
          that.SettlementSetSavs();
          break;
        // 结算弹层
        case "Settlement":
          that.SalarySheetSettlement();
          break;
      }
    },
    // 关闭弹层
    handleClose(type, bool, ref) {
      const that = this;
      let isRef = false; //控制是否执行Ref清空表单
      that[bool] = false; //关闭弹层

      switch (type) {
        // 详情弹层
        case "SettlementDetail":
          that.activeName = "first";
          that.SaleDetailPaginations.page = 1;
          that.SaleDetailPaginations.total = 0;
          that.TreatDetailPaginations.page = 1;
          that.TreatDetailPaginations.total = 0;
          (that.SettlementDetail = {
            first: [],
            second: [],
            third: {
              list: [],
              total: {},
            },
            fourth: {
              list: [],
              total: {},
            },
          }),
            (isRef = true);
          break;
      }
      if (isRef) return;
      that.$refs[ref].resetFields();
    },
  },
};
</script>

<style lang="scss">
.SalarySettlement {
  .nav_header_1 {
    // padding: 15px 15px 0px 5px;
    .vue-treeselect__control {
      width: 250px;
      // margin-right: -1px;
    }
  }
}
</style>